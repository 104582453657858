@import './alert.less';
@import './button.less';
@import './card.less';
@import './checkbox.less';
@import './empty.less';
@import './form.less';
@import './input.less';
@import './modal.less';
@import './pagination.less';
@import './popconfirm.less';
@import './segmented.less';
@import './select.less';
@import './steps.less';
@import './tabs.less';
@import './upload.less';

.ant-layout.root-ant-layout {
  .ant-layout-sider-children {
    border-right: 1px solid #e2e2e2;
  }
}

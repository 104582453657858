@import 'styles/variables.less';
@import 'styles/mixins.less';

.home {
  // min-height: 100vh;
  background-color: #1e2c60 !important;
  box-shadow: 0 10px 27px 0 rgba(0, 0, 0, 0.1);
  align-items: center;

  :global {
    .ant-layout-content {
      z-index: 1;
      margin-top: 50px;
      @media (max-width: @screen-sm) {
        margin-top: calc(38px + 25px); // header height + margin
      }
    }
    .header-center-content {
      #search-form {
        width: 100%;
      }
    }
    .ant-select-dropdown {
      margin-top: -5px !important;
    }
    #search-form {
      margin-top: 0;
    }
    .title-container {
      width: 560px;
      margin-top: 45px;

      .title {
        text-align: left;
        color: @white;
        font-weight: 600;
        // font-size: 63px;
        letter-spacing: 0;
        line-height: 72px;
        @apply text-2xl sm:text-4xl lg:text-4xl;

        b {
          display: block;
          font-weight: 700;
        }
      }

      @media (max-width: @screen-sm) {
        width: 100%;

        .title {
          font-size: 36px;
          font-weight: 700;
          letter-spacing: 0;
          line-height: 46px;
        }
      }
    }

    .stats-container {
      position: relative;
      margin-top: 20px;

      .stats {
        height: 73px;
        width: 308px;
        background-color: #fff;
        border-radius: 15px;
        padding: 0 16px;
        margin-top: 20px;
        // background-color: @bg-color;
        background-color: @white;
        box-shadow: 0 18px 30px 0 rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        // justify-content: space-around;
        .ant-divider-vertical {
          height: 32px;
          margin-top: 15px;
          border-color: @text-color-secondary;
        }
        .stats-box {
          padding: 10px;
          text-transform: uppercase;
          span {
            display: block;
            text-align: center;
            &.count {
              color: @text-color;
              font-size: 18px;
              font-weight: 600;
              letter-spacing: 0.6px;
            }
            &.name {
              // opacity: 0.5;
              color: @text-color;
              font-size: 10px;
              font-size: 8.1px;
              font-weight: bold;
              letter-spacing: 1.2px;
            }
          }
        }
      }
      @media (max-width: @screen-sm) {
        position: static;

        .stats {
          height: 59px;

          .stats-box {
            span.name {
              font-size: 8px;
              font-weight: bold;
              letter-spacing: 1.2px;
              // line-height: 38.4px;
              text-align: center;
            }
          }
        }
      }
    }
    .footer {
      box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }
  }

  :global {
    .ant-back-top {
      .ant-btn {
        svg {
          margin-top: 3px;
          font-size: 22px;
        }
      }
    }
    .page-header {
      position: sticky;
      height: 79px !important;
      background-color: #fff;
      margin-top: 0;
      padding: 0 15px !important;
      vertical-align: middle;
      align-items: center;

      @media (min-width: @screen-md) {
        height: 105px !important;
        padding: 0 20px !important;
      }
      @media (min-width: @screen-lg) {
        padding: 0 75px !important;
      }
      .center {
        ul {
          width: 90% !important;
        }
        @media (min-width: @screen-md) {
          display: flex;
          max-width: 400px;
        }
      }
    }

    .home-content {
      min-height: calc(100vh - 177.5px);
      padding: 0 15px;

      @media (min-width: @screen-md) {
        padding: 0 20px;
      }
      @media (min-width: @screen-lg) {
        padding: 0 75px;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: 'DM Serif Text', serif;
        font-weight: 500;
      }
    }
  }
}

@white: #ffffff;@blue: #2e5bff;@primary-color: #2e5bff;@green-6: #33ac2e;@red-6: #d13f3f;@red-bg: #fef7f7;@change-request-color: #fb923c;@change-request-bg-color: #fff7ed;@success-color: #33ac2e;@primary-color-faded: rgba(@primary-color, 0.15);@highlight-color: #ffff00;@height-base: 40px;@header-menu-item-height: 34px;@tile-large-bg: #41a3fd;@tile-medium-bg: #38b6fa;@tile-small-bg: #38b6fa;@grey-4: #bbbbbb;@text-color: #2d384f;@text-color-secondary: #8798ad;@section-heading-color: @text-color-secondary;@bg-color: #fafbff;@bg-color-with-opacity: rgba(224, 231, 255, 0.2);@input-color: @text-color;@input-label-color: #69707f;@input-icon-color: #999999;@input-placeholder-color: #565d6e;@input-border-color: #afb8cb;@border-color-split: @input-border-color;@picker-border-color: @input-border-color;@icon-btn-border-color: #c6cedd;@input-bg: rgba(46, 91, 255, 0.01);@input-disabled-color: #565d6e;@input-disabled-border-color: #afb8cb;@gcc-border-color: #e0e7ff;@gcc-icon-color: #bfc5d2;@bg-table-row: #f5f7fa;@gold-6: #e3ab53;@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;@screen-xs-max: (@screen-sm-min - 1px);@screen-sm-max: (@screen-md-min - 1px);@screen-md-max: (@screen-lg-min - 1px);@screen-lg-max: (@screen-xl-min - 1px);@screen-xl-max: (@screen-xxl-min - 1px);
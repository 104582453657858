.statusCard {
  :global {
    @color: red;
    .ant-steps-item-icon > .ant-steps-icon {
      font-size: 12px;
    }
    .ant-steps {
      .ant-steps-item {
        counter-increment: list;
        .ant-switch-loading,
        .ant-switch-disabled {
          cursor: not-allowed;
          opacity: 1;
        }
        .ant-steps-item-container > .ant-steps-item-tail {
          top: 20px;
          width: 2px;
          padding: 0 0 6px;
          background-color: rgba(46, 91, 255, 0.15);
          &::after {
            background-color: transparent;
          }
        }
        .ant-steps-icon {
          border-radius: 50%;
          background-color: @bg-color;
          color: darken(@bg-color, 20%);
          width: 32px;
          height: 32px;
          display: block;
          display: flex;
          justify-content: center;
          align-items: center;

          span,
          svg {
            font-family: Figtree;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 19px;
            &.check {
              font-size: 24px;
            }
          }
        }
        &.disabled:not(.ant-steps-item-active):not(.active) {
          .ant-steps-icon {
            background-color: #e9edf7;
            color: #b7c8de;
          }
        }
        .ant-steps-item-title {
          color: @text-color;
          font-family: Figtree;
          font-size: 15px;
        }
        &.ant-steps-item-active,
        &.active,
        &.done {
          .ant-steps-item-title {
            font-weight: bold;
          }
        }

        &.ant-steps-item-finish {
          .ant-steps-icon {
            background-color: lighten(#33ac2e, 35%) !important;
            color: #33ac2e;
          }
        }
        &.ant-steps-item-process {
          .ant-steps-icon {
            background-color: lighten(@primary-color, 25%) !important;
            color: @primary-color;
          }
        }
        .ant-steps-item-content {
          .ant-form-item {
            position: absolute;
            right: 0;
            top: 0;
            @media (max-width: @screen-sm) {
              position: relative;
            }
          }
        }
      }
    }
  }
}

@white: #ffffff;@blue: #2e5bff;@primary-color: #2e5bff;@green-6: #33ac2e;@red-6: #d13f3f;@red-bg: #fef7f7;@change-request-color: #fb923c;@change-request-bg-color: #fff7ed;@success-color: #33ac2e;@primary-color-faded: rgba(@primary-color, 0.15);@highlight-color: #ffff00;@height-base: 40px;@header-menu-item-height: 34px;@tile-large-bg: #41a3fd;@tile-medium-bg: #38b6fa;@tile-small-bg: #38b6fa;@grey-4: #bbbbbb;@text-color: #2d384f;@text-color-secondary: #8798ad;@section-heading-color: @text-color-secondary;@bg-color: #fafbff;@bg-color-with-opacity: rgba(224, 231, 255, 0.2);@input-color: @text-color;@input-label-color: #69707f;@input-icon-color: #999999;@input-placeholder-color: #565d6e;@input-border-color: #afb8cb;@border-color-split: @input-border-color;@picker-border-color: @input-border-color;@icon-btn-border-color: #c6cedd;@input-bg: rgba(46, 91, 255, 0.01);@input-disabled-color: #565d6e;@input-disabled-border-color: #afb8cb;@gcc-border-color: #e0e7ff;@gcc-icon-color: #bfc5d2;@bg-table-row: #f5f7fa;@gold-6: #e3ab53;@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;@screen-xs-max: (@screen-sm-min - 1px);@screen-sm-max: (@screen-md-min - 1px);@screen-md-max: (@screen-lg-min - 1px);@screen-lg-max: (@screen-xl-min - 1px);@screen-xl-max: (@screen-xxl-min - 1px);
.ant-select .ant-select-selection-placeholder {
  color: rgba(0, 0, 0, 0.65);
}

.ant-select .ant-select-selector,
.ant-select-multiple.ant-select-sm {
  height: 32px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  border-radius: 0;
  line-height: 1.5714285714285714;
  padding-top: 0;
  overflow-y: hidden;
}

.ant-select-selection-wrap {
  align-self: center;
}

.ant-select.ant-select-outlined.ant-select-in-form-item {
  height: 32px;
  .ant-select-selector {
    border-radius: 4px;
    border-width: 1px;
    border: 1px solid #afb8cb;
  }
}

.ant-select-autoheight {
  height: auto !important;

  .ant-select-selector {
    height: auto;
  }
}

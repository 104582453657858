@import 'styles/variables.less';

/*
Align content
.alint-@{horizontal-alignment}-@{verticle-alignment}
*/
.align-cc {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-lc {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.align-rc {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.focus-highlight (@color: @primary-color; @width: 2px) {
  box-shadow: inset 0px 0px 0px @width @color;
  box-sizing: border-box;
  border: none !important;
  outline: none !important;
}

.responsive(@maxWidth; @rules) {
  @media only screen and (max-width: @maxWidth) {
    @rules();
  }
}

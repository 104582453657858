@import 'styles/variables.less';
@import 'styles/mixins.less';

.header {
  z-index: 20;
  // margin-top: 10px;
  @apply px-4 md:px-4 lg:px-8 xl:px-16;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 2;
  height: 80px;
  &.search {
    // background-color: rgba(255, 255, 255, 0.9);
    // margin-top: 0;
    padding: 0 30px;
    :global {
      .left {
        .home-link {
          .logo-text {
            color: @primary-color;
            font-size: 27.79px;
            font-weight: 500;
            letter-spacing: 0.2px;
            line-height: 42.51px;
            text-align: center;
          }
        }
      }
      .right {
        .menu-trigger {
          padding: 6px;
          .anticon {
            color: #fff;
            &.primary {
              color: @primary-color;
            }
          }
        }
      }
    }
  }
  :global {
    .left {
      .align-lc();
      .home-link {
        .logo-text {
          color: #fff;
          font-size: 27.79px;
          font-weight: 500;
          letter-spacing: 0.2px;
          line-height: 42.51px;
          text-align: center;
        }
      }
    }

    .right {
      .align-rc();
      .menu-icon {
        margin-top: 2px;
        cursor: pointer;
      }
      .menu-trigger {
        background: transparent;
        border: none;
        outline: none;
        display: flex;
        align-items: center;
        border: none;
        // box-shadow: none;
        // outline: none;
        // @apply focus:ring-2 ring-white ring-offset-white ring-offset-1;
        padding: 8px;
        .anticon {
          display: block;
          margin-top: -3px;
          margin-left: -2px;
          font-size: 32px;
          color: #fff;
          cursor: pointer;
          transition: 0.3s ease-in-out;
          &.primary {
            color: @primary-color;
          }
        }
      }

      #dropdown-menu-container {
        position: relative;
        .menu-container {
          position: absolute;
          opacity: 0;
          left: -280px;
          top: 30px;
        }
        .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
          background-color: #fff;
        }
        .ant-menu-item a {
          padding-bottom: 5px;
          &:focus,
          &:hover {
            font-weight: 600;
            color: @primary-color;
          }
        }
        .ant-popover-content {
          background: #fff;

          box-shadow: 0 10px 27px 0 rgba(0, 0, 0, 0.3);
          border-radius: 15px;
          overflow: hidden;
          // border: solid 1px @primary-color;
          > .ant-popover-arrow {
            display: none;
          }
          .ant-popover-inner-content {
            width: 286px;
            padding: 10px 0 10px 5px;
            color: @text-color;
            font-weight: 600;
            .ant-menu-item {
              padding: 0 20px;
              &:hover,
              &:active,
              &.active {
                color: @text-color;
              }
              &:last-child {
                margin-bottom: 10px;
              }
              a {
                color: @text-color;
                display: block;
                padding: 0 5px;
                line-height: 36px;
                border: solid 2px transparent;
              }
            }
            .ant-menu-item-divider {
              margin: 0 20px;
              width: calc(100% - 40px);
              height: 2px;
              border-radius: 2px;
              background-color: #dddddd;
            }
          }
        }
      }
      .avatar {
        margin-left: 15px;
        width: 46px;
        height: 46px;
        @media (max-width: @screen-sm) {
          margin-left: 12px;
          width: 29px;
          height: 29px;
        }
        cursor: pointer;
        &.blue {
          height: 32px;
          width: 32px;
        }
      }
    }
  }
}

@white: #ffffff;@blue: #2e5bff;@primary-color: #2e5bff;@green-6: #33ac2e;@red-6: #d13f3f;@red-bg: #fef7f7;@change-request-color: #fb923c;@change-request-bg-color: #fff7ed;@success-color: #33ac2e;@primary-color-faded: rgba(@primary-color, 0.15);@highlight-color: #ffff00;@height-base: 40px;@header-menu-item-height: 34px;@tile-large-bg: #41a3fd;@tile-medium-bg: #38b6fa;@tile-small-bg: #38b6fa;@grey-4: #bbbbbb;@text-color: #2d384f;@text-color-secondary: #8798ad;@section-heading-color: @text-color-secondary;@bg-color: #fafbff;@bg-color-with-opacity: rgba(224, 231, 255, 0.2);@input-color: @text-color;@input-label-color: #69707f;@input-icon-color: #999999;@input-placeholder-color: #565d6e;@input-border-color: #afb8cb;@border-color-split: @input-border-color;@picker-border-color: @input-border-color;@icon-btn-border-color: #c6cedd;@input-bg: rgba(46, 91, 255, 0.01);@input-disabled-color: #565d6e;@input-disabled-border-color: #afb8cb;@gcc-border-color: #e0e7ff;@gcc-icon-color: #bfc5d2;@bg-table-row: #f5f7fa;@gold-6: #e3ab53;@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;@screen-xs-max: (@screen-sm-min - 1px);@screen-sm-max: (@screen-md-min - 1px);@screen-md-max: (@screen-lg-min - 1px);@screen-lg-max: (@screen-xl-min - 1px);@screen-xl-max: (@screen-xxl-min - 1px);
.ant-steps {
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: rgba(0, 0, 0, 0.65);
  }

  // Update active step dot button background and icon color
  .ant-steps-item.ant-steps-item-active {
    .ant-steps-icon div.dot.step-dot button {
      background-color: var(--ant-color-primary);

      svg {
        color: #fff;
      }
    }
  }
}

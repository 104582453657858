@import 'styles/variables.less';
@import 'styles/mixins.less';

@icon-color: #fff;
.layout {
  font-family: Figtree;
  min-height: 100vh;
  :global {
    a,
    button {
      @apply focus:ring-1 ring-primary;
      &.btn-primary {
        @apply focus:ring-2 ring-primary ring-offset-white ring-offset-1;
      }
    }
    .ant-menu-inline .ant-menu-item-selected::after {
      display: none;
    }
    .ant-layout-header {
      display: flex;
      align-items: center;
      padding: 0 16px 0 0;
      height: 70px;
      background-color: #002766;
      z-index: 1000;
      position: sticky;
      top: 0;

      .sidebar-trigger {
        height: 40px;
        width: 40px;
        padding: 0;
        margin-left: 9px;
        margin-right: 9px;
        border-radius: 0;
        border: none;
        background-color: none;
        box-shadow: none;

        &:focus {
          @apply ring-2 ring-primary;
        }

        svg {
          color: #fff;
          font-size: 24px;
          vertical-align: middle;
        }
      }
      .logo-text {
        color: @text-color;
        font-family: Figtree;
        font-size: 19px;
        font-weight: 500;
        letter-spacing: -0.47px;
        line-height: 23px;
        img {
          // for complete logo with text
          // height: 80px;
          // width: 280px;
          height: 60px;
          width: 100px;
          margin-left: -40px;
        }
      }
      .ant-breadcrumb {
        display: flex;
        align-items: center;
        > span {
          display: flex;
          align-items: center;
          .ant-breadcrumb-separator {
            display: flex;
          }
          &:last-child {
            color: @primary-color;
            .ant-breadcrumb-separator {
              display: none;
            }
          }
        }
        img {
          // top: 20px;
          // position: relative;
          // left: -21px;
        }
      }
      .space {
        flex-grow: 1;
      }
      .right-menu {
        @apply flex items-center space-x-2 relative h-20;
        #dropdown-menu-trigger {
          background: transparent;
          border: none;
          box-shadow: none;
          cursor: pointer;
        }
        .ant-badge {
          display: flex;
          align-items: center;
          cursor: pointer;
          .ant-badge-count,
          .ant-badge-dot {
            @apply bg-red-700 flex justify-center;
            width: 16px;
            height: 16px;
            border-radius: 12px;
            top: 5px;
            right: 7px;
            span {
              margin-right: 0px;
            }
          }
          svg {
            font-size: 36px;
            color: @icon-color;
          }
          &:hover {
            svg {
              color: darken(@icon-color, 20%);
            }
          }
        }
        #dropdown-menu-container {
          width: 0;
          position: absolute;
          right: 0;
          top: 38px;
          .menu-container {
            position: absolute;
            opacity: 0;
            left: -280px;
            top: 30px;
          }
          .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
            background-color: #fff;
            &::after {
              display: none;
            }
          }
          .ant-menu-item::after {
            display: none;
          }
          .ant-menu-item a {
            padding-bottom: 5px;
            &:focus,
            &:hover {
              font-weight: 600;
              color: @primary-color;
            }
          }
          .ant-popover-content {
            background: #fff;
            box-shadow: 0 10px 27px 0 rgba(0, 0, 0, 0.3);
            border-radius: 15px;
            overflow: hidden;
            // border: solid 1px @primary-color;
            > .ant-popover-arrow {
              display: none;
            }
            .ant-popover-inner-content {
              width: 286px;
              padding: 10px 0 10px 5px;
              color: @text-color;
              font-weight: 600;
              .ant-menu-item {
                padding: 0 20px;
                &:hover,
                &:active,
                &.active {
                  color: @text-color;
                }
                &:last-child {
                  margin-bottom: 10px;
                }
                a {
                  color: #222222;
                  font-family: Figtree;
                  display: block;
                  padding: 0 5px;
                  line-height: 36px;
                  border: solid 2px transparent;
                }
                &::after {
                  display: none;
                }
              }
              .ant-menu-item-divider {
                margin: 0 20px;
                width: calc(100% - 40px);
                height: 2px;
                border-radius: 2px;
                background-color: #dddddd;
              }
            }
          }
        }
      }
    }
    .root-ant-layout {
      z-index: 0;
      // height: calc(100vh - 50px);
      // overflow: hidden;
      // position: sticky;
      // top: 50px;

      > .ant-layout {
        background-color: #ffffff;
      }
      .sidebar-container {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.3);
        z-index: 10000;
      }
      .ant-layout-sider {
        position: sticky;
        top: 0;
        width: 261px;
        background-color: #fafafa;

        @media (max-width: @screen-xs-max) {
          width: 100%;
        }
        .sider-logo {
          margin: 25px 0 35px;
          display: flex;
          max-height: 40px;
          // justify-content: space-around;
          .ant-avatar {
            margin-right: 10px;
            // margin-left: 30px;
            height: 32px;
            min-width: 40px;
            border-radius: 7px;
            z-index: 10;
          }
          .name-container {
            p {
              margin-bottom: 0;
            }
            .greeting {
              color: @text-color;
              font-family: Figtree;
              font-size: 15px;
              font-weight: 400;
              letter-spacing: 0;
              line-height: 18px;
            }
            .name {
              color: #bfc5d2;
              font-family: Figtree;
              font-size: 15px;
              font-weight: 400;
              letter-spacing: 0;
              line-height: 22px;
              margin-top: -3px;
            }
          }
        }
        &.ant-layout-sider-collapsed {
          .sider-logo {
            // justify-content: center;
            overflow: hidden;
            .ant-avatar {
              // margin-left: 0;
              margin-right: 0;
            }
            .name-container {
              display: none;
            }
          }
          .ant-menu-title-content {
            display: none;
          }
        }
      }
    }
  }
}

// Fix layout jumping issues
:global {
  html.sidebar-collapsed .root-ant-layout .ant-layout-content,
  html.sidebar-collapsed .root-ant-layout .ant-layout-content > .layout-page {
    max-width: 1400px;
  }

  html:not(.sidebar-collapsed) .root-ant-layout .ant-layout-content,
  html:not(.sidebar-collapsed) .root-ant-layout .ant-layout-content > .layout-page {
    max-width: calc(100vw - 261px - 32px);
  }

  @media screen and (max-width: 767px) {
    html:not(.sidebar-collapsed) .root-ant-layout .ant-layout-content,
    html:not(.sidebar-collapsed) .root-ant-layout .ant-layout-content > .layout-page {
      max-width: unset;
    }
  }
}

@white: #ffffff;@blue: #2e5bff;@primary-color: #2e5bff;@green-6: #33ac2e;@red-6: #d13f3f;@red-bg: #fef7f7;@change-request-color: #fb923c;@change-request-bg-color: #fff7ed;@success-color: #33ac2e;@primary-color-faded: rgba(@primary-color, 0.15);@highlight-color: #ffff00;@height-base: 40px;@header-menu-item-height: 34px;@tile-large-bg: #41a3fd;@tile-medium-bg: #38b6fa;@tile-small-bg: #38b6fa;@grey-4: #bbbbbb;@text-color: #2d384f;@text-color-secondary: #8798ad;@section-heading-color: @text-color-secondary;@bg-color: #fafbff;@bg-color-with-opacity: rgba(224, 231, 255, 0.2);@input-color: @text-color;@input-label-color: #69707f;@input-icon-color: #999999;@input-placeholder-color: #565d6e;@input-border-color: #afb8cb;@border-color-split: @input-border-color;@picker-border-color: @input-border-color;@icon-btn-border-color: #c6cedd;@input-bg: rgba(46, 91, 255, 0.01);@input-disabled-color: #565d6e;@input-disabled-border-color: #afb8cb;@gcc-border-color: #e0e7ff;@gcc-icon-color: #bfc5d2;@bg-table-row: #f5f7fa;@gold-6: #e3ab53;@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;@screen-xs-max: (@screen-sm-min - 1px);@screen-sm-max: (@screen-md-min - 1px);@screen-md-max: (@screen-lg-min - 1px);@screen-lg-max: (@screen-xl-min - 1px);@screen-xl-max: (@screen-xxl-min - 1px);
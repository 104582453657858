@import 'styles/variables.less';
@import 'styles/mixins.less';

.navigationTabs {
  width: 100%;
  display: flex;
  align-items: center;
  :global {
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
      padding: 0 !important;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .ant-menu-overflow-item.ant-menu-item {
      &.ant-menu-item-only-child {
        width: auto;
      }
      &.ant-menu-item-selected,
      &.ant-menu-item-active {
        &::after {
          display: none;
        }
      }
    }
    // .ant-menu.ant-menu-root:focus-visible {
    //   @apply focus:ring-2 ring-white ring-offset-1 rounded-sm;
    // }
    .ant-menu.ant-menu-horizontal {
      background: transparent;
      width: 100%;
      display: flex;
      border-color: rgba(255, 255, 255, 0.25);
      .ant-menu-item {
        width: calc(100% / 3);
        height: 40px;
        text-align: center;
        margin: 0;
        color: #fff;
        font-size: 13px;
        opacity: 0.75;
        font-weight: 700;
        a {
          color: @white;
          // display: block;
          width: 100%;
          height: 40px;
          margin-top: -2px;
        }
        &.ant-menu-item-selected {
          opacity: 1;
          border-bottom: 2px solid @white;
        }
        &:hover,
        &:active,
        &:focus {
          border-bottom: 2px solid @white;
          opacity: 1;
        }
      }
    }
  }
  &.primary {
    :global {
      .ant-menu.ant-menu-horizontal {
        border-color: rgba(187, 187, 187, 0.54);
        border-width: 2px;
        .ant-menu-item {
          color: @primary-color;
          margin-bottom: -1px;
          height: 40px;
          opacity: 0.75;
          font-weight: 700;
          padding: 0;
          a {
            color: @primary-color;
            position: relative;
            margin-top: 0;
            color: @primary-color;
            font-size: 13px;
            letter-spacing: 1.08px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: -2px;
          }
          &.ant-menu-item-selected {
            opacity: 1;
            border-bottom: 2px solid @primary-color;
          }
          &:hover,
          &:active,
          &:focus {
            border-bottom: 2px solid @primary-color;
            opacity: 1;
          }
        }
      }
    }
  }
}

@white: #ffffff;@blue: #2e5bff;@primary-color: #2e5bff;@green-6: #33ac2e;@red-6: #d13f3f;@red-bg: #fef7f7;@change-request-color: #fb923c;@change-request-bg-color: #fff7ed;@success-color: #33ac2e;@primary-color-faded: rgba(@primary-color, 0.15);@highlight-color: #ffff00;@height-base: 40px;@header-menu-item-height: 34px;@tile-large-bg: #41a3fd;@tile-medium-bg: #38b6fa;@tile-small-bg: #38b6fa;@grey-4: #bbbbbb;@text-color: #2d384f;@text-color-secondary: #8798ad;@section-heading-color: @text-color-secondary;@bg-color: #fafbff;@bg-color-with-opacity: rgba(224, 231, 255, 0.2);@input-color: @text-color;@input-label-color: #69707f;@input-icon-color: #999999;@input-placeholder-color: #565d6e;@input-border-color: #afb8cb;@border-color-split: @input-border-color;@picker-border-color: @input-border-color;@icon-btn-border-color: #c6cedd;@input-bg: rgba(46, 91, 255, 0.01);@input-disabled-color: #565d6e;@input-disabled-border-color: #afb8cb;@gcc-border-color: #e0e7ff;@gcc-icon-color: #bfc5d2;@bg-table-row: #f5f7fa;@gold-6: #e3ab53;@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;@screen-xs-max: (@screen-sm-min - 1px);@screen-sm-max: (@screen-md-min - 1px);@screen-md-max: (@screen-lg-min - 1px);@screen-lg-max: (@screen-xl-min - 1px);@screen-xl-max: (@screen-xxl-min - 1px);
.cc__DatePicker {
  --ant-date-picker-hover-border-color: #5781ff;
  --ant-date-picker-hover-bg: #ffffff;
  --ant-date-picker-active-border-color: #2e5bff;
  --ant-date-picker-active-shadow: 0 0 0 2px rgba(5, 105, 255, 0.06);
  --ant-date-picker-active-bg: #ffffff;
  --ant-date-picker-cell-hover-bg: rgba(0, 0, 0, 0.04);

  .placeholder-text {
    color: #979696; // Adjust color to your desired placeholder style
    opacity: 65;
    padding: 8px 10px;
  }

  &__trigger {
    width: 100%;
    background: var(--ant-color-bg-container);
    border-width: var(--ant-line-width);
    border-style: var(--ant-line-type);
    border-color: var(--ant-color-border);
    padding: 0;
    color: var(--ant-color-text);
    font-size: var(--ant-font-size);
    line-height: 1;
    font-family: var(--ant-font-family);
    border-radius: 2px;
    transition: border var(--ant-motion-duration-mid), box-shadow var(--ant-motion-duration-mid),
      background var(--ant-motion-duration-mid);

    &[disabled] {
      color: var(--ant-color-text-disabled);
      background-color: var(--ant-color-bg-container-disabled);
      border-color: var(--ant-color-border);
      cursor: not-allowed;
    }

    &:not([disabled]):hover {
      border-color: var(--ant-date-picker-hover-border-color);
      background-color: var(--ant-date-picker-hover-bg);
    }

    &:not([disabled]):active,
    &:not([disabled]):focus,
    &:not([disabled])[aria-expanded='true'] {
      border-color: var(--ant-date-picker-active-border-color);
      box-shadow: var(--ant-date-picker-active-shadow) !important;
      outline: 0;
      background-color: var(--ant-date-picker-active-bg);
    }

    .react-aria-DateInput {
      flex-grow: 1;
      padding: 8px 10px;
      border: none;
      outline: none;
      box-shadow: none;
      text-align: left;
    }

    button.react-aria-Button,
    button.react-aria-Button:focus {
      border: none;
      outline: none;
      box-shadow: none;
      cursor: not-allowed;

      svg {
        vertical-align: middle;
      }
    }

    &:not([disabled]) button.react-aria-Button {
      cursor: pointer;
    }
  }

  &__popover {
    overflow: hidden;
    vertical-align: top;
    background: var(--ant-color-bg-elevated);
    border-radius: var(--ant-border-radius-lg);
    box-shadow: var(--ant-box-shadow-secondary);
    transition: margin var(--ant-motion-duration-slow);
    display: inline-block;
    pointer-events: auto;

    header {
      display: flex;
      padding: 16px 8px 12px 8px;
      margin-bottom: 20px;
      align-items: center;
      border-bottom: var(--ant-line-width) var(--ant-line-type) rgba(0, 0, 0, 0.1);

      h2 {
        flex-grow: 1;
        text-align: center;
        font-size: var(--ant-font-size);
        font-weight: 500;
      }

      button {
        cursor: pointer;
        opacity: 0.65;
        transition: background var(--ant-motion-duration-mid);

        &:not([aria-disabled='true']) {
          cursor: pointer;
        }

        &[aria-disabled='true'] {
          opacity: 0.3;
        }

        &:hover:not([aria-disabled='true']) {
          opacity: 1;
          transition: background var(--ant-motion-duration-mid);
        }

        svg {
          vertical-align: middle;
        }
      }
    }

    table.react-aria-CalendarGrid {
      font-size: var(--ant-font-size);
      margin: 8px;

      thead {
        tr th {
          color: rgba(0, 0, 0, 0.5);
          padding-bottom: 16px;
        }
      }

      tbody.react-aria-CalendarGridBody {
        padding: 8px;

        td > div {
          margin: 4px;
          padding: 6px;
          text-align: center;
          border: var(--ant-line-width) var(--ant-line-type) transparent;
          border-radius: var(--ant-border-radius);
          transition: background var(--ant-motion-duration-mid);

          &:not([aria-disabled='true']) {
            cursor: pointer;
          }

          &[aria-disabled='true'] {
            color: rgba(0, 0, 0, 0.5);
          }

          &[data-selected='true'],
          &[data-focused='true'] {
            outline: none;
            border: var(--ant-line-width) var(--ant-line-type) var(--ant-color-primary);
            color: var(--ant-color-primary);
          }

          &:hover:not([aria-disabled='true']) {
            background: var(--ant-date-picker-cell-hover-bg);
            transition: background var(--ant-motion-duration-mid);
          }
        }
      }
    }
  }
}

@white: #ffffff;@blue: #2e5bff;@primary-color: #2e5bff;@green-6: #33ac2e;@red-6: #d13f3f;@red-bg: #fef7f7;@change-request-color: #fb923c;@change-request-bg-color: #fff7ed;@success-color: #33ac2e;@primary-color-faded: rgba(@primary-color, 0.15);@highlight-color: #ffff00;@height-base: 40px;@header-menu-item-height: 34px;@tile-large-bg: #41a3fd;@tile-medium-bg: #38b6fa;@tile-small-bg: #38b6fa;@grey-4: #bbbbbb;@text-color: #2d384f;@text-color-secondary: #8798ad;@section-heading-color: @text-color-secondary;@bg-color: #fafbff;@bg-color-with-opacity: rgba(224, 231, 255, 0.2);@input-color: @text-color;@input-label-color: #69707f;@input-icon-color: #999999;@input-placeholder-color: #565d6e;@input-border-color: #afb8cb;@border-color-split: @input-border-color;@picker-border-color: @input-border-color;@icon-btn-border-color: #c6cedd;@input-bg: rgba(46, 91, 255, 0.01);@input-disabled-color: #565d6e;@input-disabled-border-color: #afb8cb;@gcc-border-color: #e0e7ff;@gcc-icon-color: #bfc5d2;@bg-table-row: #f5f7fa;@gold-6: #e3ab53;@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;@screen-xs-max: (@screen-sm-min - 1px);@screen-sm-max: (@screen-md-min - 1px);@screen-md-max: (@screen-lg-min - 1px);@screen-lg-max: (@screen-xl-min - 1px);@screen-xl-max: (@screen-xxl-min - 1px);
.ant-btn {
  display: flex;
  align-items: center;
  justify-content: center;

  &.ant-btn-icon-only svg {
    vertical-align: middle;
    margin-top: -1px;
  }
}

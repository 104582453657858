@import 'styles/variables.less';
@import 'styles/mixins.less';
@active-color: @white;

.authPage {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: hidden;
  max-height: 100vh;
  :global {
    .background-container {
      display: none;
      overflow: hidden;
      @media (min-width: @screen-xl) {
        display: block;
      }
      width: 50%;
      height: 100%;
      .bg-image {
        position: absolute;
        top: 0;
        // min-height: 100vh;
        min-width: 100%;
      }
      position: relative;
      .bg-info {
        height: 326px;
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 53px 0 0 48px;
        background-color: rgba(46, 56, 77, 0.66);
        color: @white;
        font-family: Figtree;
        .heading {
          font-size: 36px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 43px;
          color: @white;
          max-width: 457px;
          margin-bottom: 20px;
        }
        .text-content {
          font-size: 15px;
          letter-spacing: 0;
          line-height: 18px;
          max-width: 421px;
        }
      }
    }
    .form-content {
      background: #fff;
      flex-basis: 100%;
      min-height: 100vh;
      max-height: 100vh;
      overflow-y: auto;
      @media (min-width: @screen-xl) {
        flex-basis: 50%;
      }
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      // align-items: center;
      padding: 0 0 30px;
      .home-link-container {
        text-align: center;
        margin-top: 20px;
        // &[data-focus-visible-added] {
        //   .focus-highlight(transparent);
        // }
        img {
          width: 220px;
          height: 100px;
          margin: 0 auto;
          margin-bottom: 10px;
        }
      }
      .home-link {
        text-align: center;
        &.focus-visible {
          .focus-highlight(transparent);
          border: none !important;
          img {
            border-radius: 25px;
            // .focus-highlight(@active-color);
            @apply ring ring-primary ring-offset-white ring-offset-2;
          }
        }

        span {
          display: block;
        }
      }
      .form-area {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
        width: calc(100% - 40px);
        @media (min-width: @screen-sm) {
          width: 420px;
        }
        // margin-bottom: 40px;
        position: relative;

        .title {
          color: @text-color;
          font-family: Figtree;
          font-size: 24px;
          letter-spacing: 0;
          line-height: 47px;
          text-align: center;
        }
        .instructions {
          color: @text-color;
          font-family: Figtree;
          font-size: 15px;
          letter-spacing: 0;
          line-height: 22px;
        }
        .terms-and-conditions {
          color: @primary-color;
        }

        .ant-btn.action {
          width: 100%;
        }
        .actions {
          flex-direction: row;
          margin-top: 20px;
          a {
            color: @primary-color;
            font-weight: 600;
          }
          span + a {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.messageContainer {
  :global {
    .cross-icon {
      color: #aaaaaa;
      font-size: 24px;
      position: relative;
      top: -20px;
      left: -20px;
      cursor: pointer;
    }

    .icon-container {
      display: flex;
      justify-content: center;
      margin: -10px 0 10px;
      img.email-icon {
        height: 70px;
        width: 74px;
      }
    }
    .message-content {
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;
    }
    .heading-text {
      color: @text-color;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 34.67px;
      text-align: center;
    }
    p {
      color: #888888;
      font-size: 12.67px;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 20px;
      text-align: center;
    }
    .close-btn {
      // position: absolute;
      height: 50px;
      width: 203px;
      bottom: -10px;
    }
  }
}

@white: #ffffff;@blue: #2e5bff;@primary-color: #2e5bff;@green-6: #33ac2e;@red-6: #d13f3f;@red-bg: #fef7f7;@change-request-color: #fb923c;@change-request-bg-color: #fff7ed;@success-color: #33ac2e;@primary-color-faded: rgba(@primary-color, 0.15);@highlight-color: #ffff00;@height-base: 40px;@header-menu-item-height: 34px;@tile-large-bg: #41a3fd;@tile-medium-bg: #38b6fa;@tile-small-bg: #38b6fa;@grey-4: #bbbbbb;@text-color: #2d384f;@text-color-secondary: #8798ad;@section-heading-color: @text-color-secondary;@bg-color: #fafbff;@bg-color-with-opacity: rgba(224, 231, 255, 0.2);@input-color: @text-color;@input-label-color: #69707f;@input-icon-color: #999999;@input-placeholder-color: #565d6e;@input-border-color: #afb8cb;@border-color-split: @input-border-color;@picker-border-color: @input-border-color;@icon-btn-border-color: #c6cedd;@input-bg: rgba(46, 91, 255, 0.01);@input-disabled-color: #565d6e;@input-disabled-border-color: #afb8cb;@gcc-border-color: #e0e7ff;@gcc-icon-color: #bfc5d2;@bg-table-row: #f5f7fa;@gold-6: #e3ab53;@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;@screen-xs-max: (@screen-sm-min - 1px);@screen-sm-max: (@screen-md-min - 1px);@screen-md-max: (@screen-lg-min - 1px);@screen-lg-max: (@screen-xl-min - 1px);@screen-xl-max: (@screen-xxl-min - 1px);
@import 'styles/variables.less';

.searchPlaces {
  :global {
    .geosuggest-contaner {
      display: flex;
      position: relative;
      align-items: center;
      svg.input-prefix {
        font-size: 23px;
        margin-right: 5px;
      }
      .geosuggest {
        flex: 1;
      }
      .clear-icon {
        position: absolute;
        right: 0;
        font-size: 14px;
        top: 26px;
        color: #bbbbbb;
        cursor: pointer;
        &:hover {
          color: @text-color;
        }
      }
    }
    .geosuggest {
      font-size: 1rem;
      position: relative;
      // width: 100%;
      margin: 1em auto;
      text-align: left;
      .geosuggest__input-wrapper {
        z-index: 1000;
        // border: solid 1px #ccc;
        border: none;
        outline: none;
        // border-radius: 20px;
        overflow: none;
        .geosuggest__input {
          border: none;
          height: 40px;
          width: 100%;
          padding: 0 22px 0 10px;
          font-size: 12px;
          font-weight: 600;
          outline: none;
          // border-radius: 20px;
          border-bottom: solid 1px #ccc;
          &:focus {
            border: none;
            border-bottom: solid 1px #ccc;
          }
        }
      }
      .geosuggest__suggests-wrapper {
        .geosuggest__suggests {
          list-style-type: none;
          padding: 0;
          border: solid 1px #ccc;
          border-top-width: 0;
          &.geosuggest__suggests--hidden {
            max-height: 0;
            overflow: hidden;
            border-width: 0;
          }
          .geosuggest__item {
            padding: 5px 10px;
            cursor: pointer;
            width: 100%;
            &.geosuggest__item--active {
              background: #267dc0;
              color: #fff;
            }
          }
        }
      }
    }

    .geosuggest {
      font-size: 18px;
      font-size: 1rem;
      position: relative;
      width: 100%;
      margin: 1em auto;
      text-align: left;
    }
    .geosuggest__input {
      width: 100%;
      // border: 2px solid transparent;
      // box-shadow: 0 0 1px #3d464d;
      padding: 0.5em 1em;
      -webkit-transition: border 0.2s, box-shadow 0.2s;
      transition: border 0.2s, box-shadow 0.2s;
      &:disabled {
        background-color: #fff;
        cursor: not-allowed;
      }
    }
    .geosuggest__input:focus {
      border-color: #267dc0;
      box-shadow: 0 0 0 transparent;
    }
    .geosuggest__suggests {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      max-height: 25em;
      padding: 0;
      margin-top: -1px;
      background: #fff;
      // border: 2px solid #267dc0;
      border-top-width: 0;
      overflow-x: hidden;
      overflow-y: auto;
      list-style: none;
      z-index: 5;
      -webkit-transition: max-height 0.2s, border 0.2s;
      transition: max-height 0.2s, border 0.2s;
    }
    .geosuggest__suggests--hidden {
      max-height: 0;
      overflow: hidden;
      border-width: 0;
    }

    /**
     * A geosuggest item
     */
    .geosuggest__item {
      font-size: 18px;
      font-size: 1rem;
      padding: 0.5em 0.65em;
      cursor: pointer;
    }
    .geosuggest__item:hover,
    .geosuggest__item:focus {
      background: #f5f5f5;
    }
    .geosuggest__item--active {
      background: #267dc0;
      color: #fff;
    }
    .geosuggest__item--active:hover,
    .geosuggest__item--active:focus {
      background: #ccc;
    }
    .geosuggest__item__matched-text {
      font-weight: bold;
    }
  }
}

@white: #ffffff;@blue: #2e5bff;@primary-color: #2e5bff;@green-6: #33ac2e;@red-6: #d13f3f;@red-bg: #fef7f7;@change-request-color: #fb923c;@change-request-bg-color: #fff7ed;@success-color: #33ac2e;@primary-color-faded: rgba(@primary-color, 0.15);@highlight-color: #ffff00;@height-base: 40px;@header-menu-item-height: 34px;@tile-large-bg: #41a3fd;@tile-medium-bg: #38b6fa;@tile-small-bg: #38b6fa;@grey-4: #bbbbbb;@text-color: #2d384f;@text-color-secondary: #8798ad;@section-heading-color: @text-color-secondary;@bg-color: #fafbff;@bg-color-with-opacity: rgba(224, 231, 255, 0.2);@input-color: @text-color;@input-label-color: #69707f;@input-icon-color: #999999;@input-placeholder-color: #565d6e;@input-border-color: #afb8cb;@border-color-split: @input-border-color;@picker-border-color: @input-border-color;@icon-btn-border-color: #c6cedd;@input-bg: rgba(46, 91, 255, 0.01);@input-disabled-color: #565d6e;@input-disabled-border-color: #afb8cb;@gcc-border-color: #e0e7ff;@gcc-icon-color: #bfc5d2;@bg-table-row: #f5f7fa;@gold-6: #e3ab53;@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;@screen-xs-max: (@screen-sm-min - 1px);@screen-sm-max: (@screen-md-min - 1px);@screen-md-max: (@screen-lg-min - 1px);@screen-lg-max: (@screen-xl-min - 1px);@screen-xl-max: (@screen-xxl-min - 1px);
.ant-form-item-control-input-content {
  .ant-picker {
    width: 100%;
  }
}

.ant-form-item .ant-form-item-label {
  label {
    display: flex;
    white-space: normal;
    align-items: baseline;
  }
}

.ant-form-item .ant-form-item-explain-error {
  color: #e00004;
}

.ant-modal {
  .ant-modal-close {
    inset-inline-end: 18px;
  }

  .ant-modal-header {
    padding-bottom: 10px;
    margin-bottom: 32px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
  }

  .ant-modal-confirm-btns {
    display: flex;
    justify-content: end;
  }
}

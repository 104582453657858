.alerts {
  :global {
    .filters {
      .yes-label {
        margin-left: 10px !important;
      }
    }
    .ant-btn > svg {
      // font-size: 20px;
      &.yellow {
        @apply text-yellow-500;
      }
      &.red {
        @apply text-red-700;
      }
    }
    .email-renderer {
      max-height: 500px;
      overflow: auto;
    }
  }
}

.table {
  :global {
    .ant-table-tbody {
      .ant-table-row {
        .ant-table-cell {
          &.ant-table-row-expand-icon-cell {
            padding: 0;
            width: 5px !important;
            min-width: 0;
            max-width: 0;
          }
        }
      }
      .ant-table-expanded-row {
        .ant-table-wrapper .ant-table table .ant-table-thead > tr > th {
          height: 30px !important;
          span {
            color: @input-label-color;
            font-size: 12px !important;
          }
          &::after {
            display: none;
          }
        }
        .ant-table-wrapper .ant-table {
          min-height: 80px;
          .ant-table-row {
            border: 0;
            td {
              border-bottom: 0;
              background-color: transparent;
            }
          }
        }
        .expanded-rows-table {
          .ant-table-tbody {
            .ant-table-row {
              .ant-table-cell {
                &.ant-table-row-expand-icon-cell {
                  padding: 10px;
                  width: auto !important;
                  min-width: auto;
                  max-width: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}

@white: #ffffff;@blue: #2e5bff;@primary-color: #2e5bff;@green-6: #33ac2e;@red-6: #d13f3f;@red-bg: #fef7f7;@change-request-color: #fb923c;@change-request-bg-color: #fff7ed;@success-color: #33ac2e;@primary-color-faded: rgba(@primary-color, 0.15);@highlight-color: #ffff00;@height-base: 40px;@header-menu-item-height: 34px;@tile-large-bg: #41a3fd;@tile-medium-bg: #38b6fa;@tile-small-bg: #38b6fa;@grey-4: #bbbbbb;@text-color: #2d384f;@text-color-secondary: #8798ad;@section-heading-color: @text-color-secondary;@bg-color: #fafbff;@bg-color-with-opacity: rgba(224, 231, 255, 0.2);@input-color: @text-color;@input-label-color: #69707f;@input-icon-color: #999999;@input-placeholder-color: #565d6e;@input-border-color: #afb8cb;@border-color-split: @input-border-color;@picker-border-color: @input-border-color;@icon-btn-border-color: #c6cedd;@input-bg: rgba(46, 91, 255, 0.01);@input-disabled-color: #565d6e;@input-disabled-border-color: #afb8cb;@gcc-border-color: #e0e7ff;@gcc-icon-color: #bfc5d2;@bg-table-row: #f5f7fa;@gold-6: #e3ab53;@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;@screen-xs-max: (@screen-sm-min - 1px);@screen-sm-max: (@screen-md-min - 1px);@screen-md-max: (@screen-lg-min - 1px);@screen-lg-max: (@screen-xl-min - 1px);@screen-xl-max: (@screen-xxl-min - 1px);
.ant-segmented {
  .ant-segmented-item {
    .ant-segmented-item-label {
      font-weight: 400;
      opacity: 0.4;
      color: #000;
    }

    &.ant-segmented-item-selected {
      .ant-segmented-item-label {
        opacity: 1;
        color: #305bff;
        font-weight: 500;
      }
    }
  }
}

@import 'styles/variables.less';
@import 'styles/mixins.less';

.map {
  position: relative;
  height: 100%;

  :global {
    .directions-trigger {
      position: absolute;
      z-index: 1;
      height: 35px;
      border: none;
      border-radius: 23.5px;
      background-color: @white;
      color: darken(@primary-color, 10%);
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.19);
      svg {
        font-size: 22px;
        vertical-align: middle;
        font-weight: 600;
        margin-right: 10px;
      }
      border: solid 2px transparent;
      &:hover,
      &:active {
        color: @primary-color;
        border: solid 1px @grey-4;
      }
      &[data-focus-visible-added] {
        .focus-highlight();
      }
      @media (max-width: @screen-sm) {
        padding: 0 5px 5px;
        svg {
          margin-right: 0;
        }
      }
    }
    .search-with-move {
      position: absolute;

      height: 35px;
      z-index: 1;
      display: flex;
      align-items: center;
      padding: 0 20px;
      border: none;
      border-radius: 23.5px;
      background-color: @white;
      color: darken(@primary-color, 10%);
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.19);
      span:nth-child(1) {
        margin-top: -4px;
      }
    }
    .map-container {
      width: 100%;
      height: 100%;

      .poi-info-window {
        display: none;
      }
      .gm-style-iw.gm-style-iw-c {
        display: none;
      }
      .gm-style-iw-t::after,
      .gm-style-iw::after {
        display: none !important;
      }
    }
    // .gm-fullscreen-control {
    //   display: none !important;
    // }
    @media (max-width: @screen-sm) {
      .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
        // No need for zoom controls on mobile
        display: none;
      }
    }
  }
}

@white: #ffffff;@blue: #2e5bff;@primary-color: #2e5bff;@green-6: #33ac2e;@red-6: #d13f3f;@red-bg: #fef7f7;@change-request-color: #fb923c;@change-request-bg-color: #fff7ed;@success-color: #33ac2e;@primary-color-faded: rgba(@primary-color, 0.15);@highlight-color: #ffff00;@height-base: 40px;@header-menu-item-height: 34px;@tile-large-bg: #41a3fd;@tile-medium-bg: #38b6fa;@tile-small-bg: #38b6fa;@grey-4: #bbbbbb;@text-color: #2d384f;@text-color-secondary: #8798ad;@section-heading-color: @text-color-secondary;@bg-color: #fafbff;@bg-color-with-opacity: rgba(224, 231, 255, 0.2);@input-color: @text-color;@input-label-color: #69707f;@input-icon-color: #999999;@input-placeholder-color: #565d6e;@input-border-color: #afb8cb;@border-color-split: @input-border-color;@picker-border-color: @input-border-color;@icon-btn-border-color: #c6cedd;@input-bg: rgba(46, 91, 255, 0.01);@input-disabled-color: #565d6e;@input-disabled-border-color: #afb8cb;@gcc-border-color: #e0e7ff;@gcc-icon-color: #bfc5d2;@bg-table-row: #f5f7fa;@gold-6: #e3ab53;@screen-xs: 480px;@screen-xs-min: @screen-xs;@screen-sm: 576px;@screen-sm-min: @screen-sm;@screen-md: 768px;@screen-md-min: @screen-md;@screen-lg: 992px;@screen-lg-min: @screen-lg;@screen-xl: 1200px;@screen-xl-min: @screen-xl;@screen-xxl: 1600px;@screen-xxl-min: @screen-xxl;@screen-xs-max: (@screen-sm-min - 1px);@screen-sm-max: (@screen-md-min - 1px);@screen-md-max: (@screen-lg-min - 1px);@screen-lg-max: (@screen-xl-min - 1px);@screen-xl-max: (@screen-xxl-min - 1px);
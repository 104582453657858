.react-aria-DateSegment {
  display: inline-block;
  padding: 0 3px;
  font-size: 14px;
  background-color: transparent;
  border: none;
  text-align: center;

  &[data-type='literal'] {
    padding: 0;
  }

  &:focus {
    outline: none;
  }

  input {
    border: none;
    outline: none;
    background: transparent;
    padding: 0;
    margin: 0;
    text-align: center;
    width: auto;
    font-size: inherit;
  }

  &.focus-visible {
    background-color: var(--ant-color-primary);
    color: #fff;
  }

  &[data-placeholder='true'] {
    color: rgba(0, 0, 0, 0.45);

    &.focus-visible {
      color: rgba(255, 255, 255, 0.45);
    }
  }
}
